import React,{useState} from 'react'
import { useTheme,makeStyles,withStyles } from '@material-ui/core/styles';
import { hBox,vBox } from "gx-web-ui/utils/flex";
import Typography from '@material-ui/core/Typography'
import take from 'lodash/take'
import Button from '@material-ui/core/Button';

import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import AgentCard from '@src/components/agent/AgentCard'

const AgencyTeam = (props) => {
    const {agency} = props
    const styles = useStyles()
    const theme = useTheme()
    const [viewMoreOpened, setViewMoreOpened] = useState(false)
    
    const agents = agency[":agents"].filter(e => e.status === 'active')

    return (
        <div>
            <Typography className={styles.title}>About Team</Typography>
            <div className={styles.container}>
                <GridList cellHeight={'auto'} cols={3} spacing={16}>
                    {(viewMoreOpened?agents:take(agents,6)).map((data)=>
                    <GridListTile cols={1} key={data.id}>
                        <AgentCard agent={data}/>
                    </GridListTile>
                    )}
                </GridList>
                {agents.length>6 &&
                <div className={styles.moreButtonContainer}>
                    <Button variant='outlined' className={styles.btn} onClick={() => setViewMoreOpened(current=>!current)}>
                        {viewMoreOpened?'View less':'View more'}
                    </Button>
                </div>
                }
            </div>
        </div>
    )
}
const useStyles = makeStyles(theme=>({
    container:{
        marginTop:16
    },
    title:{
        fontWeight:600,
        fontSize:20
    },
    moreButtonContainer:{
        ...hBox('center','center'),
        marginTop:16
    },
    btn:{
        width:180
    }
}))

export default AgencyTeam
