import React,{useEffect, useState} from 'react'
import { Layout } from "antd"

import LandingPageOptions from '@src/components/LandingPageOptions'
import ExploreHeader from '@src/components/layout/ExploreHeader'
import Notifier from '@src/components/Notifier'
import MePanel from '@src/components/setting/MePanel'
import AgencyDetail from '@src/components/agency/AgencyDetail'
import withRoot from '@src/withRoot'
import {inject,observer} from 'mobx-react'
import compose from "recompose/compose";
const { Content } = Layout

const AgencyPageTemplate = (props) => {
    const {
        pageContext: { data:agencyData },
        appStore
    } = props
    // console.log(agencyData, 'check here')

    const [agencyInfo, setAgencyInfo] = useState(null)
    const [isLoading, setIsLoading] = useState(true)
    const loadData = async (id) => {
        setIsLoading(true)
        const result = await appStore.clientApi.getAgencyDetail_(id)
        setAgencyInfo(result)
        setIsLoading(false)
    }

    useEffect(() => {
        loadData(agencyData.id)
    }, [])

    return (
        <Layout style={{backgroundColor:'white',maxWidth:1440,margin: "0 auto"}}>
            <Notifier />
            <ExploreHeader/>
            <Content style={{position:"relative"}}>
                <MePanel/>
                {
                    !isLoading && <AgencyDetail agency={agencyInfo}/>
                }
                <div style={{
                    margin:32,
                    marginLeft:64,
                    marginRight:64
                }}>
                    <LandingPageOptions/>
                </div>
            </Content>
        </Layout>
    )
}

export default compose(inject('appStore'),observer,withRoot)(AgencyPageTemplate)