import React,{useState} from 'react'
import { useTheme,makeStyles,withStyles } from '@material-ui/core/styles';
import { hBox,vBox } from "gx-web-ui/utils/flex";
import Typography from '@material-ui/core/Typography'

const AgentAgencyAbout = (props) => {
    const { about, name } = props
    const styles = useStyles()
    return (
        <div>
            <Typography className={styles.title}>About {name}</Typography>
            <div dangerouslySetInnerHTML={{__html:about}} className={styles.aboutContent}/>
        </div>
    )
}

const useStyles = makeStyles(theme=>({
    title:{
        fontWeight:600,
        fontSize:20
    },
    aboutContent:{
        marginTop:16,
        fontSize:16
    }
}))

export default AgentAgencyAbout
