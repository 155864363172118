import React,{useState} from 'react'
import { useTheme,makeStyles,withStyles } from '@material-ui/core/styles';
import { hBox,vBox } from "gx-web-ui/utils/flex";
import Typography from '@material-ui/core/Typography'

import compose from 'recompose/compose'
import {inject,observer} from 'mobx-react'

import {
    isTablet,
    isMobile
} from "react-device-detect";

import AgencyBanner from './AgencyBanner'
import AgencyInfoPanel from './AgencyInfoPanel'
import AgencyTeam from './AgencyTeam'
import AgencyListingPanel from './AgencyListingPanel'
import SaleStatus from '../agent/SaleStatus'
import AgentAgencyAbout from '../agent/AgentAgencyAbout'


const AgencyDetail = (props) => {
    const { agency } = props
    const styles = useStyles()
    const panels = [
        {
            component:<AgencyInfoPanel agency={agency}/>,
            show:true
        },
        {
            component:<SaleStatus 
                forSale={agency[':statForClient']?.statForSale||0} 
                sold={agency[':statForClient']?.statSold||0}
                forRent={agency[':statForClient']?.statForRent||0}
                leased={agency[':statForClient']?.statLeased||0}/>,
            show:true
        },
        {
            component:<AgentAgencyAbout 
                about={agency.about}
                name={agency.name}/>,
            show:true
        },
        {
            component:<AgencyTeam 
                agency={agency}/>,
            show:true
        },
        {
            component:<AgencyListingPanel 
                agency={agency}/>,
            show:true
        },
    ]
    return (
        <div className={styles.root}>
            <div className={styles.breadCrumb}>
                <Typography variant='body1'>Home > Agency > {agency.name||''}</Typography>
            </div>
            {agency[':resources'].BANNER &&
            <div className={styles.banner}>
                <AgencyBanner agency={agency}/>
            </div>
            }
            {panels.filter(o=>o.show).map(({component},index)=>
            <React.Fragment key={index}>
                {index!==0 && <div className={styles.divider}/>}
                {component}
            </React.Fragment>
            )}
        </div>
    )
}

const useStyles = makeStyles(theme=>({
    root:{
        margin:32,
        marginLeft:isMobile?16:64,
        marginRight:isMobile?16:64,
    },
    spaceBetween:{
        ...hBox('center','space-between')
    },
    alignH:{
        ...hBox('center')
    },
    breadCrumb:{
        marginBottom:32
    },
    divider:{
        height:1,
        width:'100%',
        backgroundColor:theme.palette.grey[300],
        marginTop:32,
        marginBottom:32,
    },
    banner:{
        marginBottom:32
    }
}))

export default compose(inject('uiStore'),observer)(AgencyDetail)

