import React,{useState} from 'react'
import { useTheme,makeStyles,withStyles } from '@material-ui/core/styles';
import { hBox,vBox } from "gx-web-ui/utils/flex";
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography'
import { typography } from '../../theme/theme';

const AgencyInfoPanel = (props) => {
    const {agency} = props
    const styles = useStyles()
    return (
        <div className={styles.spaceBetween}>
            <div>
                <Typography variant={'body2'} style={{fontWeight:'bold',fontSize:32}}>{agency.name}</Typography>
                <Typography variant={'body2'} color='textSecondary' style={{fontSize:16}}>{agency[":company"].businessAddress||''}</Typography>
            </div>
            <div>
                <img src={agency.logo} className={styles.logo}/>
            </div>
        </div>
    )
}

const useStyles = makeStyles(theme=>({
    spaceBetween:{
        ...hBox('top','space-between')
    },
    alignH:{
        ...hBox('center')
    },
    avatar:{
        width: 200,
        height: 200,
    },
    logo:{
        height:55,
        width:'auto',
        marginBottom:4,
        cursor:"pointer",
    },
    agencyInfo:{
        ...vBox('right')
    },
    nameText:{
        fontWeight:'bold',
        fontSize:32
    },
    subText:{
        fontSize:16
    },
    agentInfo:{
        marginLeft:16
    },
    btn:{
        marginRight:16,
        width:168
    },
    chatBtn:{
        width:168
    }
}))

export default AgencyInfoPanel
