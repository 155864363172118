import React,{useState} from 'react'
import { observer, inject } from 'mobx-react';
import { hBox,vBox } from "gx-web-ui/utils/flex";
import { makeStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose'
import findIndex from 'lodash/findIndex'
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';

import {ReactComponent as Play} from '@src/assets/svgIcons/Play.svg'
import {motion} from 'framer-motion'

import PropertyCarousel from '@src/components/property/PropertyCarousel'
import "react-responsive-carousel/lib/styles/carousel.min.css";



const AgencyBannerCover = (props)=>{
    const { 
        children,
        currentIndex,
        setCurrentIndex,
        videoBannerIndex,
        totalNumber
     } = props
     const styles = useCoverStyles()
     const hasVideo = videoBannerIndex >= 0
     const bottomRightActions = [
         {
             key:'play',
             Icon:Play,
             onClick:()=>{
                 if(videoBannerIndex){
                     setCurrentIndex(videoBannerIndex)
                 }  
             },
             disabled:!hasVideo,
         },

     ]
     return  <div className={styles.root}>
                {children}
                <div className={styles.gradient}/>
                <motion.div className={styles.gradientTop} animate={videoBannerIndex>=0 && videoBannerIndex===currentIndex?{opacity:0}:{opacity:1}}/>
                <motion.div className={styles.bottomRightActions} animate={videoBannerIndex>=0 && videoBannerIndex===currentIndex?{opacity:0}:{opacity:1}}>
                    {bottomRightActions.map(({Icon,onClick,disabled,key})=>(
                        <ButtonBase key={key} disableRipple disabled={disabled} onClick={onClick}>
                            <Icon height={25} width={25} fill={disabled?'rgba(255,255,255,0.5)':'white'} className={styles.iconButton}/>
                        </ButtonBase>
                    ))}
                </motion.div>
                <motion.div className={styles.counter} animate={videoBannerIndex>=0 && videoBannerIndex===currentIndex?{opacity:0}:{opacity:1}}>
                    <Typography variant='subtitle1' className={styles.title}>{currentIndex+1}  /  {totalNumber}</Typography>
                </motion.div>
            </div>
}

const useCoverStyles = makeStyles(theme=>({
    root: {
        flex: 1,
        position: 'relative',
        overflow:'hidden'
    },
    gradient: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: 'linear-gradient(0deg, rgba(40,40,40,0.75) 0%, rgba(0,0,0,0) 25%)',
        pointerEvents:'none'
    },
    gradientTop:{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: 'linear-gradient(180deg, rgba(40,40,40,0.75) 0%, rgba(0,0,0,0) 20%, rgba(0,212,255,0) 100%)',
        pointerEvents:'none'
    },
    header:{
        position:'absolute',
        top:16,
        left:16,
        ...hBox('center'),
        pointerEvents:'none'
    },
    headerText:{
        // ...typography.body1,
        fontSize:22,
        fontWeight:300,
        color:'white'
    },
    image:{
        width:40,
        height:40,
        resizeMode: 'cover',
        marginRight:8,
    },
    topRightActions:{
        position:'absolute',
        top:16,
        right:8,
        ...hBox('center'),
    },
    iconButton:{
        textShadow: '4px 4px 10px rgba(0, 0, 0, 0.79)',
        marginLeft:12,
        marginRight:8,
    },
    bottomRightActions:{
        position:'absolute',
        bottom:16,
        right:8,
        ...hBox('center'),
    },
    counter:{
        position:'absolute',
        bottom:16,
        left:16,
        ...hBox('center'),
    },
    title:{
        color:'white',
    }
}))


const AgencyBanner = (props) => {
    const { agency, generalPropertyStore } = props
    const { processBanners } = generalPropertyStore
    const [currentIndex, setCurrentIndex] = useState(0)

    const getBanners = (agency)=>{
        let banners = agency[':resources']['BANNER'] || []
        banners = processBanners(banners)
        const videoIndex = findIndex(banners,'video')
        if(videoIndex>=0){
            let reorderBanners = banners.filter(banner=>!banner.video)
            reorderBanners.splice(1,0,banners[videoIndex])
            banners = reorderBanners
        }
        return banners 
    }
    const banners = getBanners(agency)
    const videoBannerIndex = findIndex(banners,'video')
    return (
        <div style={{
            userSelect:'none',
            borderRadius:5,
            overflow:'hidden'
        }}>
            <AgencyBannerCover
                currentIndex={currentIndex}
                setCurrentIndex={setCurrentIndex}
                videoBannerIndex={videoBannerIndex}
                totalNumber={banners.length}
            >
            <PropertyCarousel 
                banners={banners}
                setCurrentIndex={setCurrentIndex}
                currentIndex={currentIndex}
                height={600}
            />
            </AgencyBannerCover>
        </div>
    )
}

export default compose(inject('generalPropertyStore'))(AgencyBanner)
