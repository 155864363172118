import React,{useState} from 'react'
import { useTheme,makeStyles,withStyles } from '@material-ui/core/styles';
import { hBox,vBox } from "gx-web-ui/utils/flex";
import Typography from '@material-ui/core/Typography'
import SaleStatePanel from '@src/components/SaleStatePanel'


const SaleStatus = (props) => {
    const {forSale=0, sold=0, forRent=0, leased=0} = props
    const styles = useStyles()
    return (
        <div className={styles.spaceBetween}>
            <div>
                <Typography className={styles.title}>Properties</Typography>
                <Typography variant='body2' color='textSecondary' className={styles.desc}>Past 12 month period</Typography>
            </div>
            <SaleStatePanel forSale={forSale} sold={sold} forRent={forRent} leased={leased}/>
        </div>
    )
}

const useStyles = makeStyles(theme=>({
    spaceBetween:{
        ...hBox('top','space-between')
    },
    alignH:{
        ...hBox('center')
    },
    title:{
        fontWeight:600,
        fontSize:20
    },
    desc:{
        fontSize:12
    },
    verticalDivider:{
        height:'100%',
        width:1,
        backgroundColor:theme.palette.grey[300],
        marginLeft:32,
        marginRight:32,
    }
}))

export default SaleStatus
