import React, {useState} from 'react'
import { useTheme,makeStyles,withStyles } from '@material-ui/core/styles';
import { hBox,vBox } from "gx-web-ui/utils/flex";
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography'
import { typography } from '../../theme/theme';
import Button from '@material-ui/core/Button'
import BlackBtn from '@src/components/common/BlackBtn'
import {Tooltip} from 'antd'

import compose from 'recompose/compose'
import {inject,observer} from 'mobx-react'

import {ReactComponent as Message} from '@src/assets/svgIcons/Message a.svg'
import {ReactComponent as Phone} from '@src/assets/svgIcons/Phone 1.svg'

const AgentCard = (props) => {
    const {agent,style,uiStore, onSendMessage} = props
    const {checkLogin,switchMeSetting, setIsMessagePanelVisible, isMessagePanelVisible, setMessageReceiverId, setSentPropertyListing} = uiStore
    const styles = useStyles()
    const delegate = Boolean(agent.delegate) 
    const name = agent.preferredName || agent[':user'].name
    const avatar = agent[":user"]?.avatar
    const agencyLogo = agent[":agency"]?.logo
    const email = agent.email
    const [showPhoneNumber, setShowPhoneNumber] = useState(false)

    let mobile = agent.mobile;
    if (mobile.startsWith('+61')) {
        mobile = '0' + mobile.substr(3);
    }

    const onOpenAgent = ()=>{
        // switchMeSetting()
        window.open(`/agent/${agent.id}`,'_blank')
    }

    return (
        <div className={styles.root} style={style}>
            <div className={styles.alignH}>
                <div className={styles.avatarRoot} onClick={onOpenAgent}>
                    <Avatar src={avatar} className={styles.avatar}/>
                    {agencyLogo && !delegate &&
                    <Avatar src={agencyLogo} className={styles.logo}/>
                    }
                </div>
                <div className={styles.info}>
                    <Typography variant='subtitle1' className={styles.name} onClick={onOpenAgent}>{name}</Typography>
                    {(email && email.length > 32) ?
                    <Tooltip placement="topRight" title={email} overlayStyle={{width: 650}}>
                        <Typography variant='body1' className={styles.email}>
                            {email.length > 32 ? `${email.substr(0, 28)} ...` : email}
                        </Typography>
                    </Tooltip> :
                    <Typography variant='body1' className={styles.email}>{email}</Typography>
                    }
                </div>
            </div>
            <div className={styles.alignH} style={{marginTop:18}}>
                <Button variant='outlined' fullWidth className={styles.btn} onClick={() => setShowPhoneNumber(!showPhoneNumber)}>
                    {showPhoneNumber ? mobile : 
                    <>
                    <Phone height={16} width={16} fill='black' style={{marginRight:8}}/>
                    Call
                    </>
                    }
                </Button>
                <BlackBtn fullWidth onClick={(e) => {
                    
                    checkLogin(async () => {
                        if (!isMessagePanelVisible) {
                            if (onSendMessage) {
                                await onSendMessage(agent[':user'].id)
                            }

                            setSentPropertyListing(true)
                            setMessageReceiverId(agent[':user'].id)
                            window.scroll({top: 0, left: 0, behavior: 'smooth'})

                        }

                        setIsMessagePanelVisible(!isMessagePanelVisible)
                    })()
                }}>
                    <Message height={16} width={16} fill='white' style={{marginRight:8}}/>
                    Chat
                </BlackBtn>
            </div>
        </div>
    )
}

const useStyles = makeStyles(theme=>({
    root:{
        width:'100%',
        padding:24,
        paddingTop:32,
        paddingBottom:32,
        backgroundColor:theme.palette.grey[100],
        borderRadius:5,
    },
    avatarRoot:{
        position:'relative',
        cursor:'pointer'
    },
    alignH:{
        ...hBox('center')
    },
    avatar:{
        width: 70,
        height: 70,
    },
    logo:{
        width: 27,
        height: 27,
        position:'absolute',
        right:0,
        bottom:0,
    },
    name:{
        fontWeight:600,
        cursor:'pointer'
    },
    email:{
    },
    info:{
        marginLeft:16
    },
    btn:{
        marginRight:8
    }
}))

export default compose(inject('uiStore'),observer)(AgentCard)
