import React from 'react'
import { hBox,vBox } from "gx-web-ui/utils/flex";
import Typography from '@material-ui/core/Typography'
import { useTheme,makeStyles,withStyles } from '@material-ui/core/styles';

const margin = 48

const StateBlock = ({value,title,style})=>{
    const styles = useStyles()
    return <div className={styles.block} style={style}>
        <Typography className={styles.valueText}>{value}</Typography>
        <Typography variant='body2' color='textSecondary' className={styles.titleText}>{title}</Typography>
    </div>
}

const SaleStatePanel = (props) => {
    const {
        forSale=0,
        sold=0,
        forRent=0,
        leased=0,
    } = props

    const styles = useStyles()

    return (
        <div className={styles.root}>
            <StateBlock value={forSale} title={'For sale'}/>
            <StateBlock value={sold} title={'Sold'} style={{marginLeft:margin}}/>
            <div className={styles.verticalDivider}/>
            <StateBlock value={forRent} title={'For Rent'}/>
            <StateBlock value={leased} title={'Leased'} style={{marginLeft:margin}}/>
        </div>
    )
}
const useStyles = makeStyles(theme=>({
    verticalDivider:{
        height:40,
        width:1,
        backgroundColor:theme.palette.grey[300],
        marginLeft:margin,
        marginRight:margin,
    },
    block:{
        ...vBox('center')
    },
    valueText:{
        fontSize:32,
        fontWeight:600,
    },
    root:{
        ...hBox('center')
    },
    titleText:{
        fontSize:12,
    }
}))

export default SaleStatePanel
