import React, { useEffect, useState } from 'react';
import { withSnackbar } from 'notistack';
import { inject, observer } from 'mobx-react';
import { autorun } from 'mobx';
import compose from 'recompose/compose'

const Notifier = (props) => {
    const [displayed, setDisplayed] = useState([])
    const storeDisplayed = (id) => {
        setDisplayed([displayed, id])
    };
    useEffect(() => {
        autorun(() => {
            const {
                notifications,
                removeSnackbar,
            } = props.uiStore
            const { enqueueSnackbar } = props

            notifications.forEach((notification) => {
                // Do nothing if snackbar is already displayed
                if (displayed.includes(notification.key)) return;
                // Display snackbar using notistack
                enqueueSnackbar(notification.message, notification.options);
                // Keep track of snackbars that we've displayed
                storeDisplayed(notification.key);
                // Dispatch action to remove snackbar from mobx store
                removeSnackbar(notification.key);
            });
        });
    }, [])
    return null
}

export default compose(withSnackbar,inject('uiStore'),observer)(Notifier)
